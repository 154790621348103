import React from "react";
import styles from "./styles.module.scss";
import { TContentWithImage } from "@page-builder/ts/generated";
import { useDomainContext } from "@hooks/useDomainContext";
import { replacePlaceholders } from "@lib/sharedUtils";

const ContentWithImage = ({
    props = {},
}: {
    props: TContentWithImage["props"];
}) => {
    const { text, image, buttonText, withScrollButton } = props;
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const { category, visitDetails } = useDomainContext();

    return (
        <section className={styles["content-with-image-container-preview"]}>
            <div className={styles.contentContainer}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: text
                            ? replacePlaceholders(text, {
                                  category: category?.name,
                                  region: visitDetails.region,
                                  city: visitDetails?.city,
                              })
                            : "",
                    }}
                    className={`prose md:prose-md max-w-none ${styles["text-block"]}`}
                />
                {withScrollButton && (
                    <button
                        className={`${styles.scrollToTopPreview} text-sm sm:text-lg`}
                        onClick={() => scrollToTop()}
                    >
                        {buttonText}
                    </button>
                )}
            </div>
            <div className={styles.imageContainer}>
                {image ? <img src={image ?? ""} alt="image" /> : null}
            </div>
        </section>
    );
};

export default ContentWithImage;
